import { constants } from '@wsb/guac-widget-core';
import { COMMON_BUTTON_CONFIG } from '../../common/constants';

const { colorPackCategories, buttons } = constants;
const { LIGHT, LIGHT_ALT, LIGHT_COLORFUL, DARK, DARK_ALT, DARK_COLORFUL, COLORFUL, MVP } =
  constants.paintJobs;

const id = 'layout14';

export default {
  id,
  name: 'craft',
  packs: {
    color: '#283878',
    font: 'muli'
  },
  logo: {
    font: 'primary'
  },
  packCategories: {
    color: colorPackCategories.NEUTRAL
  },
  headerProperties: {
    alignmentOption: 'center'
  },
  paintJobs: [LIGHT, LIGHT_ALT, LIGHT_COLORFUL, COLORFUL, DARK_COLORFUL, DARK_ALT, DARK],
  defaultPaintJob: MVP,
  buttons: {
    primary: {
      fill: buttons.fills.SOLID,
      shape: buttons.shapes.PILL,
      decoration: buttons.decorations.NONE,
      shadow: buttons.shadows.NONE,
      color: buttons.colors.PRIMARY
    },
    secondary: {
      fill: buttons.fills.SOLID,
      decoration: buttons.decorations.NONE,
      shadow: buttons.shadows.NONE,
      color: buttons.colors.PRIMARY
    },
    ...COMMON_BUTTON_CONFIG
  }
};
